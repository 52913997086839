import "./App.css";
import { Link } from "react-router-dom";

export default function App() {
  return (
    <div>
      <h1 class="text-3xl font-bold underline">Hello world!</h1>
      <nav
        style={{
          borderBottom: "solid 1px",
          paddingBottom: "1rem",
        }}
      >
        <Link to="/resume">Resume</Link> | <Link to="/projects">Projects</Link>
      </nav>
    </div>
  );
}
